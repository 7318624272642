import { EventEmitter, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable, map, of, shareReplay, tap } from 'rxjs';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { MrhstApi } from 'src/lib/repository/mrhst/mrhst.api';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { PackagegoodsApi } from 'src/lib/repository/packagegoods/packagegoods.api';
import {
  IPackagegoods,
  PackagegoodsAvailMrhstDto,
} from 'src/lib/repository/packagegoods/packagegoods.model';
import { environment } from '../../environments/environment';
import {
  MrhstDescriptionComponent,
  MrhstDescriptionDialogData,
} from '../components/mrhst-description/mrhst-description.component';
import { TabData } from '../components/tab-list/tab-list.component';
import { DialogService } from './dialog.service';

/**
 * 매장 타입
 */
export type MrhstType =
  | 'HOTEL'
  | 'RESTAURANT'
  | 'GOLF'
  | 'HOT_SPRING'
  | 'ATTRACTION'
  | 'FARM'
  | 'CAR';

/**
 * 매장 타입별 라벨
 */
export const MRHST_TYPE_TO_LABEL: Record<string, string> = {
  HOTEL: '호텔',
  RESTAURANT: '식사',
  GOLF: '골프',
  HOT_SPRING: '온천',
  ATTRACTION: '관광지',
  FARM: '농원',
};

/**
 * 매장 타입별 아이콘
 */
export const MRHST_TYPE_TO_ICON: Record<string, string> = {
  HOTEL: 'building',
  RESTAURANT: 'egg-fried',
  GOLF: 'balloon-fill',
  HOT_SPRING: 'water',
  ATTRACTION: 'geo-fill',
  FARM: 'flower1',
};

/**
 * 매장 타입별 아이콘
 */
export const MRHST_TYPE_TO_MAT_ICON: Record<string, string> = {
  HOTEL: 'apartment',
  RESTAURANT: 'restaurant',
  GOLF: 'sports_golf',
  HOT_SPRING: 'hot_tub',
  ATTRACTION: 'pin_drop',
  FARM: 'grass',
};

/**
 * 패키지 상세 페이지 제어 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class PackagegoodsDetailService {
  /**
   * 호텔 시설 아이디 목록
   */
  hotelMrhstIdList: number[] = [];

  /**
   * 관광지 시설 아이디 목록
   */
  attractionMrhstIdList: number[] = [];

  /**
   * 골프 시설 아이디 목록
   */
  golfMrhstIdList: number[] = [];

  /**
   * 온천 시설 아이디 목록
   */
  hotSpringMrhstIdList: number[] = [];

  /**
   * 식사 시설 아이디 목록
   */
  restaurantMrhstIdList: number[] = [];

  /**
   * 아이디별 시설 맵
   */
  mrhstMap: Map<number, IMrhst> = new Map();

  packagegoods: IPackagegoods | null = null;

  packagegoodsLoaded: EventEmitter<IPackagegoods> = new EventEmitter();

  constructor(
    private packagegoodsService: PackagegoodsApi,
    private mrhstService: MrhstApi,
    private dialogService: DialogService,
  ) {
    this.packagegoodsLoaded
      .pipe(
        tap((packagegoods) => {
          this.packagegoods = packagegoods;
        }),
      )
      .subscribe();
  }

  /**
   * 초기화
   */
  clear(): void {
    this.hotelMrhstIdList = [];
    this.attractionMrhstIdList = [];
    this.golfMrhstIdList = [];
    this.hotSpringMrhstIdList = [];
    this.restaurantMrhstIdList = [];
  }

  /**
   * 패키지 상품의 이용 가능한 시설로부터 초기화
   * @param packagegoods 패키지 상품
   */
  initFromPackagegoodsId$(id: number): Observable<PackagegoodsAvailMrhstDto> {
    const params = {
      // 일정에만 존재하는 시설도 포함
      includeScheduleOnly: true,
    };

    return this.packagegoodsService.getAvailMrhstList(id, params).pipe(
      tap((response) => {
        this.setMrhstMapFromAvailableMrhstList(response);
      }),
    );
  }

  isShowScheduleFixedData(): boolean {
    const now = dayjs();
    const start = dayjs(environment.showPackagegoodsScheduleFixedDataFrom);
    const end = dayjs(environment.showPackagegoodsScheduleFixedDataTo);

    if (now.isAfter(start) && now.isBefore(end)) {
      return true;
    }

    return false;
  }

  /**
   * 패키지 상세 탭 목록 반환
   */
  getTabList(): TabData {
    const tabList = [{ label: '일정', value: 'schedule' }];

    if (this.hotelMrhstIdList?.length) {
      tabList.push({ label: '호텔', value: 'hotel' });
    }

    if (this.attractionMrhstIdList?.length) {
      tabList.push({ label: '관광지', value: 'attraction' });
    }

    if (this.golfMrhstIdList?.length) {
      tabList.push({ label: '골프', value: 'golf' });
    }

    if (this.hotSpringMrhstIdList?.length) {
      tabList.push({ label: '온천', value: 'hotSpring' });
    }

    if (this.restaurantMrhstIdList?.length) {
      tabList.push({ label: '식사', value: 'restaurant' });
    }

    return tabList;
  }

  /**
   * 매장 타입 반환
   */
  getMrhstType(mrhst: IMrhst): MrhstType {
    const { mrhstTypeEnum, mrhstSubType } = mrhst;
    let type: MrhstType;

    // TODO: 송영, 가이드 설명 필요성 검토
    if (
      mrhstTypeEnum !== 'HOTEL' &&
      mrhstTypeEnum !== 'RESTAURANT' &&
      mrhstTypeEnum !== 'ATTRACTION' &&
      mrhstTypeEnum !== 'FARM' &&
      mrhstTypeEnum !== 'CAR'
    ) {
      throw new Error('설명 가능한 유형의 매장이 아닙니다.');
    }

    // 저장
    type = mrhstTypeEnum;

    // 관광지이며
    if (mrhstTypeEnum === 'ATTRACTION') {
      // 골프 또는 온천이면
      if (mrhstSubType === 'GOLF' || mrhstSubType === 'HOT_SPRING') {
        type = mrhstSubType;
      }
    }

    return type;
  }

  /**
   * 가지고 있는 매장 또는 서버에서 가져온 매장 데이터 반환
   */
  getMrhst$(id: number): Observable<IMrhst> {
    const mrhst = this.mrhstMap.get(id);

    if (mrhst) {
      return of(mrhst);
    }

    return this.mrhstService.findItem(id).pipe(
      shareReplay(),
      map((reloadedMrhst) => {
        this.mrhstMap.set(id, reloadedMrhst);
        return reloadedMrhst;
      }),
    );
  }

  /**
   * 매장 설명 다이얼로그
   */
  openMrhstDescriptionDialog$(
    id: number,
    fallbackMrhst?: IMrhst,
  ): Observable<any> {
    const mrhst = this.mrhstMap.get(id) ?? fallbackMrhst;

    const data: MrhstDescriptionDialogData = {
      title:
        MRHST_TYPE_TO_LABEL[
          this.getMrhstType(mrhst!) as keyof typeof MRHST_TYPE_TO_LABEL
        ],
      mrhst,
    };

    const dialog$ = this.dialogService.open(MrhstDescriptionComponent, data, {
      // maxWidth: 'auto',
      // panelClass: ['mrhst-description-panel'],
    }).onDialogClosing;

    return dialog$;
  }

  /**
   * 객실 설명 다이얼로그
   */
  openHotelGoodsDescriptionDialog$(hotelGoods: IHotelGoods): Observable<any> {
    const data: MrhstDescriptionDialogData = {
      title: '객실',
      hotelGoods,
    };

    const dialog$ = this.dialogService.open(MrhstDescriptionComponent, data, {
      // maxWidth: 'auto',
      // panelClass: ['mrhst-description-panel'],
    }).onDialogClosing;

    return dialog$;
  }

  private setMrhstMapFromAvailableMrhstList(
    availMrhstList: PackagegoodsAvailMrhstDto,
  ): void {
    this.clear();

    availMrhstList.hotelMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
      this.hotelMrhstIdList.push(mrhst.id!);
    });

    availMrhstList.attractionMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
      this.attractionMrhstIdList.push(mrhst.id!);
    });

    availMrhstList.golfMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
      this.golfMrhstIdList.push(mrhst.id!);
    });

    availMrhstList.hotSpringMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
      this.hotSpringMrhstIdList.push(mrhst.id!);
    });

    availMrhstList.restaurantMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
      this.restaurantMrhstIdList.push(mrhst.id!);
    });

    availMrhstList.carMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
    });

    availMrhstList.guideMrhstList.forEach((mrhst) => {
      this.mrhstMap.set(mrhst.id!, mrhst);
    });
  }
}
