import { Component, Input, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

// TODO: 아코디언 목록을 만들어주는 컴포넌트 추가 검토
// TODO: 범용화 검토
// TODO: 타입 추가 검토
/**
 * 패키지 상세 페이지 아코디언
 */
@Component({
  selector: 'trnty-packagegoods-detail-accordion',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './packagegoods-detail-accordion.component.html',
  styleUrls: ['./packagegoods-detail-accordion.component.scss'],
})
export class PackagegoodsDetailAccordionComponent implements OnInit {
  /**
   * 아코디언 펼침 여부
   */
  expanded = true;

  /**
   * 부트스트랩 아코디언의 접고 펼치기 위한 쿼리셀렉트용 아이디
   */
  collapseId?: string;

  /**
   * 아코디언 아이디
   *
   * 중복되면 여러 아코디언이 같이 접히고 펼쳐짐
   */
  @Input() id: any;

  /**
   * 라벨
   * @example 출국/체류/귀국 또는 ~일차
   */
  @Input() label?: string;

  /**
   * 아코디언 버튼 텍스트
   * @example 인천, 후쿠오카, 구마모토
   */
  @Input() buttonText?: string;

  /**
   * 초기화시 열림 여부
   */
  @Input() isShowOnInit = true;

  /**
   * 접고 펼칠수 있는지 여부
   */
  @Input() canToggle = true;

  constructor() {}

  ngOnInit(): void {
    if (!this.id) {
      throw new Error('id는 필수입니다.');
    }

    this.collapseId = `${this.id}-collapse`;
  }

  /**
   * 아코디언 토글 클릭시
   */
  onToggleClick(): void {
    this.expanded = !this.expanded;
  }
}
