import { Component, Input, OnInit } from '@angular/core';
import Swiper from 'swiper';

/**
 * 제목, 글, 이미지 목록 형태의 설명
 */
export interface DescriptionArticleData {
  title?: string;
  content?: string;
  imgUrlList?: string[];
}

/**
 * 제목, 글, 이미지 목록 형태의 설명 컴포넌트
 */
@Component({
  selector: 'trnty-description-article',
  templateUrl: './description-article.component.html',
  styleUrls: ['./description-article.component.scss'],
})
export class DescriptionArticleComponent implements OnInit {
  /**
   * 이미지 스와이퍼
   */
  swiper?: Swiper;

  /**
   * 제목, 글, 이미지 목록
   */
  @Input() data?: DescriptionArticleData;

  constructor() {}

  ngOnInit(): void {}

  /**
   * 스와이퍼 초기화시
   */
  onSwiperInit(swiper: Swiper): void {
    this.swiper = swiper;
  }

  /**
   * 썸네일 클릭시
   */
  onThumbnailClick(index: number): void {
    // 스와이퍼 이미지를 클릭한 썸네일로 변경
    this.swiper?.slideToLoop(index);
  }
}
