import { Injectable } from '@angular/core';
import { Observable, map, mergeMap, of } from 'rxjs';
import { AttractionGoodsApiService } from 'src/lib/repository/attraction-goods/attraction-goods.api';
import { IAttractionGoods } from 'src/lib/repository/attraction-goods/attraction-goods.model';
import { CarGoodsApiService } from 'src/lib/repository/car-goods/car-goods.api';
import { FarmGoodsApiService } from 'src/lib/repository/farm-goods/farm-goods.api';
import { HotelGoodsApi } from 'src/lib/repository/hotel-goods/hotel-goods.api';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { RestaurantGoodsApiService } from 'src/lib/repository/restaurant-goods/restaurant-goods.api';
import { IRestaurantGoods } from 'src/lib/repository/restaurant-goods/restaurant-goods.model';
import { Utils } from 'src/lib/utils';
import { ICarGoods } from '../../lib/repository/car-goods/car-goods.model';
import { IFarmGoods } from '../../lib/repository/farm-goods/farm-goods.model';
import { DescriptionArticleData } from '../components/mrhst-description/description/description-article/description-article.component';
import { DescriptionTableData } from '../components/mrhst-description/description/description-table/description-table.component';
import {
  DescriptionGolfJson,
  DescriptionHotelJson,
} from '../components/mrhst-description/mrhst-description.model';
import {
  MrhstType,
  PackagegoodsDetailService,
} from './packagegoods-detail.service';

/**
 * 좌표
 */
export type Coordinate = { lat: number; lng: number };

/**
 * 매장 설명 자식요소 데이터
 */
export interface DescriptionData {
  category: string;
  title: string;
  articleData?: DescriptionArticleData;
  tableData?: DescriptionTableData;
  listData?: string[];
  coordinate?: Coordinate;
  childDataList?: DescriptionData[];
}

/**
 * 매장 설명 제어 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class MrhstDescriptionService {
  constructor(
    private hotelGoodsService: HotelGoodsApi,
    private restaurantGoodsService: RestaurantGoodsApiService,
    private attractionGoodsService: AttractionGoodsApiService,
    private farmGoodsService: FarmGoodsApiService,
    private carGoodsService: CarGoodsApiService,
    private packagegoodsDetailService: PackagegoodsDetailService,
  ) {}

  /**
   * 매장 설명
   */
  getDataFromMrhst$(mrhst: IMrhst): Observable<DescriptionData> {
    let reloadedMrhst: IMrhst;
    let type: MrhstType;

    return this.packagegoodsDetailService.getMrhst$(mrhst.id!).pipe(
      mergeMap((mrhstResponse) => {
        // 매장 정보 임시 저장
        reloadedMrhst = mrhstResponse;
        // 타입
        type = this.packagegoodsDetailService.getMrhstType(mrhstResponse);
        // 검색조건
        const params = {
          mrhstId: mrhstResponse.id,
          displayFlg: 1,
        };

        // 숙박 상품
        if (type === 'HOTEL') {
          return (
            this.hotelGoodsService
              // TODO: 검색조건 표준화 검토
              .getHomepageList(params)
          );
        }

        // 식당 상품
        if (type === 'RESTAURANT') {
          return (
            this.restaurantGoodsService
              // TODO: 검색조건 표준화 검토
              .getHomepageList(params)
          );
        }

        // 관광지 상품
        if (type === 'ATTRACTION' || type === 'GOLF' || type === 'HOT_SPRING') {
          return (
            this.attractionGoodsService
              // TODO: 검색조건 표준화 검토
              .getHomepageList(params)
          );
        }

        if (type === 'FARM') {
          return (
            this.farmGoodsService
              // TODO: 검색조건 표준화 검토
              .getHomepageList(params)
          );
        }

        if (type === 'CAR') {
          return (
            this.carGoodsService
              // TODO: 검색조건 표준화 검토
              .getHomepageList(params)
          );
        }

        return of([]);
      }),
      map((content) => {
        let descriptionData: DescriptionData;

        // 타입별 설명 설정
        if (type === 'HOTEL') {
          descriptionData = this.getDescriptionAsHotel(reloadedMrhst, content);
        } else if (type === 'RESTAURANT') {
          descriptionData = this.getDescriptionAsRestaurant(
            reloadedMrhst,
            content,
          );
        } else if (type === 'GOLF') {
          descriptionData = this.getDescriptionAsGolf(reloadedMrhst, content);
        } else if (type === 'HOT_SPRING') {
          descriptionData = this.getDescriptionAsHotSpring(
            reloadedMrhst,
            content,
          );
        } else if (type === 'ATTRACTION') {
          descriptionData = this.getDescriptionAsAttraction(
            reloadedMrhst,
            content,
          );
        } else if (type === 'FARM') {
          descriptionData = this.getDescriptionAsFarm(reloadedMrhst, content);
        } else if (type === 'CAR') {
          descriptionData = this.getDescriptionAsCar(reloadedMrhst, content);
        }

        return descriptionData!;
      }),
    );
  }

  /**
   * 객실 상품 설명
   */
  getDataFromHotelGoods(hotelGoods: IHotelGoods): DescriptionData {
    // 호텔 상품 기본 정보
    const { goodsNm, goodsCn, goodsCnJson, imgUrl, imgFileDataList } =
      hotelGoods;

    let imgUrlList: string[] = [];

    const fileDataPathList = imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(imgUrl!, []);
    }

    // 객실
    const {
      hasAirConditioner,
      hasCoffeePot,
      hasCoffer,
      hasHairDryer,
      hasRefrigerator,
      hasTv,
      hasWifi,
    } = Utils.getParsedJson(goodsCnJson!, {});

    // TODO: 항복별 번역 검토
    // 객실 시설 목록
    const listData: string[] = [];
    this.pushStringIfTrue(listData, '에어컨', hasAirConditioner);
    this.pushStringIfTrue(listData, '커피포트', hasCoffeePot);
    this.pushStringIfTrue(listData, '금고', hasCoffer);
    this.pushStringIfTrue(listData, '헤어드라이어', hasHairDryer);
    this.pushStringIfTrue(listData, '냉장고', hasRefrigerator);
    this.pushStringIfTrue(listData, 'TV', hasTv);
    this.pushStringIfTrue(listData, '와이파이', hasWifi);

    const data: DescriptionData = {
      articleData: {
        content: goodsCn,
        imgUrlList,
      },
      category: 'Room',
      title: goodsNm!,
      listData,
    };

    return data;
  }

  /**
   * 식당 상품 설명
   */
  getDataFromRestaurantGoods(
    restaurantGoods: IRestaurantGoods,
  ): DescriptionData {
    // 호텔 상품 기본 정보
    const { goodsNm, goodsCn, imgUrl, imgFileDataList } = restaurantGoods;

    let imgUrlList: string[] = [];

    const fileDataPathList = imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(imgUrl!, []);
    }

    const data: DescriptionData = {
      articleData: {
        content: goodsCn,
        imgUrlList,
      },
      category: 'menu',
      title: goodsNm!,
    };

    return data;
  }

  /**
   * 농원 상품 설명
   * FIXME: 타입 추가
   */
  getDataFromFarmGoods(farmGoods: IFarmGoods): DescriptionData {
    // 호텔 상품 기본 정보
    const { goodsNm, goodsCn, imgUrl, imgFileDataList } = farmGoods;

    let imgUrlList: string[] = [];

    const fileDataPathList = imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(imgUrl!, []);
    }

    const data: DescriptionData = {
      articleData: {
        content: goodsCn,
        imgUrlList,
      },
      category: 'goods',
      title: goodsNm!,
    };

    return data;
  }

  /**
   * 송영 상품 설명
   * FIXME: 타입 추가
   */
  getDataFromCarGoods(carGoods: ICarGoods): DescriptionData {
    // 송영 상품 기본 정보
    const { goodsNm, goodsCn, imgUrl, imgFileDataList } = carGoods;

    let imgUrlList: string[] = [];

    const fileDataPathList = imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(imgUrl!, []);
    }

    const data: DescriptionData = {
      articleData: {
        content: goodsCn,
        imgUrlList,
      },
      category: 'goods',
      title: goodsNm!,
    };

    return data;
  }

  /**
   * 관광지 상품 설명
   */
  getDataFromAttractionGoods(
    attractionGoods: IAttractionGoods,
  ): DescriptionData {
    // 호텔 상품 기본 정보
    const { goodsNm, goodsCn, imgUrl, imgFileDataList } = attractionGoods;

    let imgUrlList: string[] = [];

    const fileDataPathList = imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(imgUrl!, []);
    }

    const data: DescriptionData = {
      articleData: {
        content: goodsCn,
        imgUrlList,
      },
      category: 'goods',
      title: goodsNm!,
    };

    return data;
  }

  /**
   * 매장 공통 설명
   */
  private getCommonDescriptionData(
    category: string,
    mrhst: IMrhst,
  ): DescriptionData {
    const { imgUrlListJson, mrhstNm, mrhstCn, imgFileDataList } = mrhst;

    // 이미지 목록 파싱
    let imgUrlList: string[] = [];

    const fileDataPathList = imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(imgUrlListJson!, []);
    }

    // 좌표 획득
    const coordinate = this.getCoordinate(mrhst);
    //  공통 데이터 생성
    const data: DescriptionData = {
      category,
      title: mrhstNm!,
      articleData: {
        content: mrhstCn,
        imgUrlList,
      },
      coordinate,
    };

    return data;
  }

  /**
   * 호텔 설명
   */
  private getDescriptionAsHotel(
    mrhst: IMrhst,
    hotelGoodsList: IHotelGoods[],
  ): DescriptionData {
    // 호텔
    const { checkInTime, checkOutTime, roomCnt, star }: DescriptionHotelJson =
      Utils.getParsedJson(mrhst.descriptionJson!, {});

    // TODO: 항복별 번역 검토
    // 호텔 표
    const tableData: DescriptionTableData = [];
    tableData.push(['호텔 등급', star ?? '']);
    tableData.push(['객실 수', roomCnt ?? '']);
    tableData.push(['체크인', checkInTime ?? '']);
    tableData.push(['체크아웃', checkOutTime ?? '']);

    // 객실
    const childDataList: DescriptionData[] = [];

    hotelGoodsList?.forEach((hotelGoods) => {
      childDataList.push(this.getDataFromHotelGoods(hotelGoods));
    });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('REPO.STRING.hotel', mrhst),
      tableData,
      childDataList,
    };

    return descriptionData;
  }

  /**
   * 레스토랑 설명
   */
  private getDescriptionAsRestaurant(
    mrhst: IMrhst,
    restaurantGoodsList: IRestaurantGoods[],
  ): DescriptionData {
    // 레스토랑
    // const { menuList }: DescriptionRestaurantJson = Utils.getParsedJson(
    //   mrhst.descriptionJson,
    //   {}
    // );

    // 메뉴
    const childDataList: DescriptionData[] = [];

    restaurantGoodsList?.forEach((restaurantGoods) => {
      childDataList.push(this.getDataFromRestaurantGoods(restaurantGoods));
    });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('restaurant', mrhst),
      childDataList,
    };

    return descriptionData;
  }

  /**
   * 농원 설명
   */
  private getDescriptionAsFarm(
    mrhst: IMrhst,
    farmGoodsList: any[],
  ): DescriptionData {
    // 농원
    const childDataList: DescriptionData[] = [];

    farmGoodsList?.forEach((farmGoods) => {
      childDataList.push(this.getDataFromFarmGoods(farmGoods));
    });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('farm', mrhst),
      childDataList,
    };

    return descriptionData;
  }

  /**
   * 송영 설명
   */
  private getDescriptionAsCar(
    mrhst: IMrhst,
    carGoodsList: any[],
  ): DescriptionData {
    // 송영
    const childDataList: DescriptionData[] = [];

    carGoodsList?.forEach((carGoods) => {
      childDataList.push(this.getDataFromCarGoods(carGoods));
    });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('car', mrhst),
      childDataList,
    };

    return descriptionData;
  }

  /**
   * 골프장 설명
   */
  private getDescriptionAsGolf(
    mrhst: IMrhst,
    attractionGoodsList: IAttractionGoods[],
  ): DescriptionData {
    // 골프장
    const {
      designer,
      openYear,
      hasApproachRange,
      hasCaddy,
      hasDrivingRange,
      hasPracticeGreen,
      hasToppingRange,
    }: DescriptionGolfJson = Utils.getParsedJson(mrhst.descriptionJson!, {});

    // 골프장 표
    const tableData: DescriptionTableData = [];

    // FIXME: 임시 비활성화
    // if (designer) {
    //   tableData.push(['설계자', designer]);
    // }

    // if (openYear) {
    //   tableData.push(['개장연도', openYear]);
    // }

    // TODO: 항복별 번역 검토
    // 골프장 목록
    const listData: string[] = [];
    // this.pushStringIfTrue(listData, '어프로치 연습장', hasApproachRange);
    // this.pushStringIfTrue(listData, '캐디', hasCaddy);
    // this.pushStringIfTrue(listData, '드라이빙 레인지', hasDrivingRange);
    // this.pushStringIfTrue(listData, '연습용 그린', hasPracticeGreen);
    // this.pushStringIfTrue(listData, '탑핑 연습장', hasToppingRange);

    const childDataList: DescriptionData[] = [];

    // attractionGoodsList?.forEach((attractionGoods) => {
    //   childDataList.push(this.getDataFromRestaurantGoods(attractionGoods));
    // });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('golfCourse', mrhst),
      tableData,
      listData,
      childDataList,
    };

    return descriptionData;
  }

  /**
   * 온천 설명
   */
  private getDescriptionAsHotSpring(
    mrhst: IMrhst,
    attractionGoodsList: IAttractionGoods[],
  ): DescriptionData {
    const childDataList: DescriptionData[] = [];

    attractionGoodsList?.forEach((attractionGoods) => {
      childDataList.push(this.getDataFromRestaurantGoods(attractionGoods));
    });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('hotspring', mrhst),
      childDataList,
    };

    return descriptionData;
  }

  /**
   * 관광지 설명
   */
  private getDescriptionAsAttraction(
    mrhst: IMrhst,
    attractionGoodsList: IAttractionGoods[],
  ): DescriptionData {
    const childDataList: DescriptionData[] = [];

    attractionGoodsList?.forEach((attractionGoods) => {
      childDataList.push(this.getDataFromRestaurantGoods(attractionGoods));
    });

    const descriptionData: DescriptionData = {
      ...this.getCommonDescriptionData('tourismDestination', mrhst),
      childDataList,
    };

    return descriptionData;
  }

  /**
   * `value`가 `true`이면 `list`에 `string`을 입력
   * @param list 입력시킬 배열
   * @param string 입력 할 문자
   * @param value 입력 여부
   */
  private pushStringIfTrue(
    list: string[],
    string: string,
    value: boolean,
  ): void {
    if (value) {
      list.push(string);
    }
  }

  /**
   * 매장 좌표 반환
   */
  private getCoordinate(mrhst: IMrhst): Coordinate {
    const { lat, lng } = mrhst;

    if (lat && lng) {
      return { lat, lng };
    }

    return null!;
  }
}
