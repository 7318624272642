import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { BatchInsertable } from '../batch-insertable';
import { IPackagegoodsBookingCalendarDto } from '../packagegoods/packagegoods.model';
import {
  IHotelGoods,
  IHotelGoodsListDto,
  IHotelGoodsSearchKeyword,
} from './hotel-goods.model';

@Injectable({
  providedIn: 'root',
})
export class HotelGoodsApi
  extends PageRepositoryService<IHotelGoods>
  implements BatchInsertable
{
  override baseUri = 'api/hotelGoods';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  /**
   * 호텔 상품 일괄 등록
   */
  batchInsert(body: any, params: any): Observable<any> {
    this.isDetailLoading = true;
    return this.http
      .post<IHotelGoods>(`${this.apiServerUrl}/${this.baseUri}/list`, body, {
        params,
      })
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }

  /**
   * 상품 검색 키워드 조회
   */
  getSearchKeyword(params: any): Observable<IHotelGoodsSearchKeyword[]> {
    return this.http.get<IHotelGoodsSearchKeyword[]>(
      `${this.apiServerUrl}/${this.baseUri}/searchKeyword`,
      { params },
    );
  }

  /**
   * 재고 조회
   */
  getInventory(params: any): Observable<IPackagegoodsBookingCalendarDto[]> {
    return this.http.get<IPackagegoodsBookingCalendarDto[]>(
      `${this.apiServerUrl}/${this.baseUri}/inventory`,
      { params },
    );
  }

  /**
   * 상품 목록 조회
   */
  getHomepageList(params: any): Observable<IHotelGoods[]> {
    return this.http.get<IHotelGoods[]>(
      `${this.apiServerUrl}/${this.baseUri}/homepage`,
      { params },
    );
  }

  /**
   * 상품 목록 조회
   */
  getHomepageBookingList(params: any): Observable<IHotelGoodsListDto[]> {
    return this.http.get<IHotelGoodsListDto[]>(
      `${this.apiServerUrl}/${this.baseUri}/homepage/bookingList`,
      { params },
    );
  }

  /**
   * 상품 상세
   */
  findItemHomepage(id: number): Observable<IHotelGoods> {
    this.isDetailLoading = true;
    return this.http
      .get<IHotelGoods>(
        `${this.apiServerUrl}/${this.baseUri}/homepage/detail/${id}`,
      )
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }
}
