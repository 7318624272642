import { Component, Input, OnInit } from '@angular/core';

/**
 * 목록 형태의 설명 컴포넌트
 */
@Component({
  selector: 'trnty-description-list',
  templateUrl: './description-list.component.html',
  styleUrls: ['./description-list.component.scss'],
})
export class DescriptionListComponent implements OnInit {
  /**
   * 목록
   */
  @Input() data?: string[];

  constructor() {}

  ngOnInit(): void {}
}
