<div class="accordion">
  <article class="accordion-item" [id]="id">
    <div class="accordion-header">
      <h3 class="accordion-header-label">
        {{ label! | translate }}
      </h3>

      <div
        class="accordion-button"
        type="button"
        [class.no-toggle]="!canToggle"
        [attr.data-bs-toggle]="canToggle ? 'collapse' : ''"
        [attr.data-bs-target]="canToggle ? '#' + collapseId : ''"
        [attr.aria-controls]="canToggle ? collapseId : ''"
      >
        {{ buttonText }}
      </div>
    </div>

    <div
      class="accordion-collapse collapse"
      [id]="collapseId"
      [class.show]="canToggle ? isShowOnInit : true"
    >
      <div class="accordion-body">
        <ng-content></ng-content>
      </div>
    </div>
  </article>
</div>
