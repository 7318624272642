import { Component, Input, OnInit } from '@angular/core';

/**
 * 표 데이터
 */
export type DescriptionTableData = [label: string, content: any][];

/**
 * 표 형태의 설명 컴포넌트
 */
@Component({
  selector: 'trnty-description-table',
  templateUrl: './description-table.component.html',
  styleUrls: ['./description-table.component.scss'],
})
export class DescriptionTableComponent implements OnInit {
  /**
   * 표 데이터
   */
  @Input() data?: DescriptionTableData;

  constructor() {}

  ngOnInit(): void {}
}
