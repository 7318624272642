import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageRepositoryService } from '../abstract-repository.service';
import { BatchInsertable } from '../batch-insertable';
import { IFarmGoods } from './farm-goods.model';

@Injectable({
  providedIn: 'root',
})
export class FarmGoodsApiService
  extends PageRepositoryService<IFarmGoods>
  implements BatchInsertable
{
  override baseUri = 'api/farmGoods';

  constructor(protected override http: HttpClient) {
    super(http);
  }

  batchInsert(body: any, params: any): Observable<any> {
    this.isDetailLoading = true;
    return this.http
      .post<IFarmGoods>(`${this.apiServerUrl}/${this.baseUri}/list`, body, {
        params,
      })
      .pipe(
        finalize(() => {
          this.isDetailLoading = false;
        }),
        this.handleError(),
      );
  }

  getHomepageList(params: any): Observable<IFarmGoods[]> {
    return this.http.get<IFarmGoods[]>(
      `${this.apiServerUrl}/${this.baseUri}/homepage`,
      { params },
    );
  }
}
