<div class="description-article">
  <!-- 이미지 래퍼 -->
  <div class="article-image-wrapper" *ngIf="data?.imgUrlList?.length">
    <!-- 스와이퍼 -->
    <trnty-image-carousel
      [imgUrlList]="data!.imgUrlList!"
      (swiperInit)="onSwiperInit($event)"
    ></trnty-image-carousel>
  </div>

  <div class="article-content-wrapper">
    <!-- 썸네일 목록 -->
    <div
      class="article-thumbnail-container"
      *ngIf="(data?.imgUrlList?.length ?? 0) > 1"
    >
      <!-- 썸네일 -->
      <img
        class="article-thumbnail"
        loading="lazy"
        [src]="imgUrl"
        [class.selected]="i === swiper?.realIndex"
        (click)="onThumbnailClick(i)"
        *ngFor="let imgUrl of data?.imgUrlList; index as i"
      />
    </div>
    <!-- 제목 -->
    <h3 class="article-name" *ngIf="data?.title">{{ data?.title }}</h3>
    <!-- 본문 -->
    <p class="article-content">{{ data?.content }}</p>
  </div>
</div>
