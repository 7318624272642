import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import {
  DescriptionData,
  MrhstDescriptionService,
} from 'src/app/services/mrhst-description.service';
import { IHotelGoods } from 'src/lib/repository/hotel-goods/hotel-goods.model';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { Utils } from 'src/lib/utils';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';

/**
 * 다이얼로그 데이터
 */
export interface MrhstDescriptionDialogData {
  /**
   * 제목
   */
  title: string;

  /**
   * 매장
   */
  mrhst?: IMrhst;

  /**
   * 객실
   */
  hotelGoods?: IHotelGoods;
}

/**
 * 매장 상세 설명 컴포넌트
 */
@Component({
  selector: 'trnty-mrhst-description',
  templateUrl: './mrhst-description.component.html',
  styleUrls: ['./mrhst-description.component.scss'],
})
export class MrhstDescriptionComponent
  extends DialogAbstract
  implements OnInit
{
  override dialogRef!: DialogRef<this, void, MrhstDescriptionDialogData>;

  /**
   * 설명
   */
  descriptionData?: DescriptionData;

  /**
   * 다이얼로그 제목
   */
  title?: string;

  /**
   * 유튜브 동영상 목록
   */
  youtubeVideoList?: string[];

  /**
   * 매장
   */
  @Input() mrhst?: IMrhst;

  @Input() showMrhst: boolean = true;

  /**
   * 매장
   */
  @Input() hotelGoods?: IHotelGoods;

  get isDialog(): boolean {
    return !!this.dialogRef;
  }

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private mrhstDescriptionService: MrhstDescriptionService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    if (this.dialogRef) {
      // Angular HostBinding 으로는 ngOnInit 단계에서 설정되지 않아 Element 직접 접근
      this.elementRef.nativeElement.children[0].className =
        'modal-dialog modal-dialog-centered modal-fullscreen-sm-down mrhst-description-panel modal-dialog-scrollable';
    }

    super.ngOnInit();

    this.title = this.dialogRef?.data?.title;
    this.mrhst = this.dialogRef?.data?.mrhst || this.mrhst;
    this.hotelGoods = this.dialogRef?.data?.hotelGoods || this.hotelGoods;
    this.setDescriptionData();
  }

  private setDescriptionData(): void {
    if (this.mrhst) {
      this.mrhstDescriptionService
        .getDataFromMrhst$(this.mrhst)
        .pipe(
          tap((descriptionData) => {
            this.descriptionData = descriptionData;
            // mrhst api 호출 후 확인 가능
            this.youtubeVideoList = Utils.getParsedJson(
              this.mrhst?.youtubeVideoListJson!,
              [],
            );
          }),
        )
        .subscribe();
      return;
    }

    if (this.hotelGoods) {
      this.descriptionData = this.mrhstDescriptionService.getDataFromHotelGoods(
        this.hotelGoods,
      );
      return;
    }

    throw new Error('매장 또는 객실 데이터가 입력되지 않았습니다.');
  }
}
