<table class="description-table">
  <ng-container *ngFor="let row of data">
    <!-- 소제목 -->
    <th class="description-table-label">
      {{ row[0] }}
    </th>
    <!-- 내용 -->
    <td class="description-table-content">
      {{ row[1] }}
    </td>
  </ng-container>
</table>
