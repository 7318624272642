<div>
  <div [class.modal-content]="isDialog">
    @if (title) {
      <div class="modal-header">
        <h1 class="modal-title fs-5">{{ title | translate }}</h1>
        <button class="btn-close" (click)="close(false)"></button>
      </div>
    }

    @if (descriptionData) {
      <div [class.modal-body]="isDialog">
        <trnty-packagegoods-detail-accordion
          *ngIf="showMrhst"
          [id]="'parent-' + mrhst?.id || hotelGoods?.id"
          [label]="descriptionData.category"
          [buttonText]="descriptionData.title"
        >
          <trnty-description-article
            [data]="descriptionData.articleData"
            *ngIf="descriptionData.articleData"
          ></trnty-description-article>

          <!-- 영상 목록 -->
          <div class="video-container">
            <div
              trnty-video-wrapper
              [innerHTML]="youtubeVideo | safeHtml"
              *ngFor="let youtubeVideo of youtubeVideoList"
            ></div>
          </div>

          <trnty-description-table
            [data]="descriptionData.tableData"
            *ngIf="descriptionData.tableData?.length"
          ></trnty-description-table>

          <trnty-description-list
            [data]="descriptionData.listData"
            *ngIf="descriptionData.listData?.length"
          ></trnty-description-list>

          <div class="mrhst-map" *ngIf="descriptionData.coordinate">
            <app-google-map
              [markers]="[descriptionData.coordinate]"
              [center]="descriptionData.coordinate"
              [zoom]="5"
            ></app-google-map>
          </div>
        </trnty-packagegoods-detail-accordion>

        <trnty-packagegoods-detail-accordion
          [label]="childData?.category"
          [buttonText]="childData?.title"
          [id]="'child-' + (mrhst?.id || hotelGoods?.id) + '-' + i"
          *ngFor="let childData of descriptionData.childDataList; index as i"
        >
          <ng-container *ngIf="childData.articleData">
            <!-- 제목, 글, 이미지 목록 -->
            <trnty-description-article
              [data]="childData.articleData"
            ></trnty-description-article>
          </ng-container>

          <!-- 표 -->
          <trnty-description-table
            [data]="childData.tableData"
            *ngIf="childData.tableData?.length"
          >
          </trnty-description-table>

          <!-- 목록 -->
          <trnty-description-list
            [data]="childData.listData"
            *ngIf="childData.listData?.length"
          >
          </trnty-description-list>
        </trnty-packagegoods-detail-accordion>
      </div>
    }
  </div>
</div>
